import {Button, Card, Input, Typography} from "antd";
import React, {useContext, useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import '../index.css';
import logo from "../kitslogo.png";
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {getBaseUrl} from "../components/ApiConstants";
import { AppContext } from "../contexts/AppContext";


const {Title} = Typography;

const LoginPage = () => {

    const { showNotification } = useContext(AppContext)

    let isAuthenticate = ''
    const [username, setUsername] = useState()
    const [password, setPassword] = useState()

    const onChangeEmailInput = (value) => {
        setUsername(value.target.value)
    };

    const onChangePasswordInput = (value) => {
        setPassword(value.target.value)
    };


    let navigate = useNavigate();

    async function loginAuth() {

        const data = JSON.stringify({
            "username": username,
            "password": password
        });

        const config = {
            method: 'post',
            url: getBaseUrl() + '/auth',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        await axios(config)
            .then(function (response) {
                if (response.status === 200){
                    const token = Object.values(response.data)
                    localStorage.setItem('token', token[0])
                    localStorage.setItem('username', username)
                }
            })
            .catch(function (error) {
                console.log(error?.response)
                // TODO: handle specific errors
                if (!error?.response?.data) {
                    // Generic error
                    showNotification('Ett fel uppstod', 'Inloggningen misslyckades', 'error')
                }
            });
    }
    // Interceptor för alla API-anrop som hämtar token.
    async function login() {

        await loginAuth()

        if (localStorage.getItem('token') !== null){
            navigate("/report")
        }
    }

    let styles = {
        container: {
            backgroundImage: 'url("https://kitscontractor.se/wp-content/uploads/2021/03/Underhall-1.jpg")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            minWidth: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderColor: '#1a4223'
        },
        loginContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',

        },
        input: {
            marginBottom: 20,
            borderColor: '#439D5B'
        },
        buttonLogin: {
            width: '100%',
            backgroundColor: '#439D5B',
        }

    };

    return (
        <div style={styles.container} className="container">

            <Card style={styles.loginContainer}>


                <img className="imageLogo" src={logo} alt=""/>
                <Input style={styles.input}
                       prefix={<UserOutlined className="site-form-item-icon"/>}
                       value={username}
                       onChange={(value) => onChangeEmailInput(value)}
                       placeholder="Företagsnamn"/>

                <Input.Password
                    style={styles.input}
                    prefix={<LockOutlined className="site-form-item-icon"/>}
                    value={password}
                    onChange={onChangePasswordInput}
	            onPressEnter={login}
                    placeholder="Lösenord"/>

                <Button  style={styles.buttonLogin} onClick={login}>Logga in </Button>
            </Card>


            {/*<Button*/}
            {/*    color="red"*/}
            {/*    onClick={login}*/}
            {/*    type="primary">Logga in</Button>*/}
            {/*{username} {password}*/}

        </div>
    );
}


export default LoginPage;
