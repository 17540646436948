import CargoManagement from "./CargoManagement";
import CustomerManagement from "./CustomerManagement";
import MaterialManagement from "./MaterialManagement";
import ControlLog from "./ControlLog"


const ComponentSwitcher = (props) => {
    let testVar = props.navValue

    if (testVar === 1) {
        return (
                <CargoManagement></CargoManagement>
        )
    } else if (testVar === 0){
        return (
            <CustomerManagement></CustomerManagement>
        )
    } else if (testVar === 2){
            return <MaterialManagement></MaterialManagement>
    } else if (testVar === 3){
            return <ControlLog></ControlLog>
            
}

}

export default ComponentSwitcher;
