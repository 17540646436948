import CustomerList from "./CustomerList";
import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {
    Button,
    Form,
    Input,
    Table,
    Typography,
    Switch,
    Select,
    Tag,
    Skeleton,
    Empty
} from "antd";
import {
    LockOutlined,
    UserOutlined,
    PlusOutlined,
    MailOutlined,
    FieldBinaryOutlined,
    ProfileOutlined
} from '@ant-design/icons';
import {getBaseUrl} from "./ApiConstants";
import Checkbox from "antd/es/checkbox/Checkbox";
import { AppContext } from "../contexts/AppContext";


const CustomerManagement = () => {

    const { showNotification } = useContext(AppContext)

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [formNewUser] = Form.useForm();
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [fullList, setFullList] = useState();
    const [filteredList, setFilteredList] = useState();
    const [filterText, setFilterText] = useState('')
    const [showCreateNewUser, setShowCreateNewUser] = useState(false);

    const fetchAllUsers = async () => {
        await fetch(getBaseUrl() + '/user/', {
            headers: new Headers({
                    Authorization: 'Bearer ' + localStorage.getItem(('token'))
                }
            )
        }).then(r => r.json())
            .then(r => {
                //console.log('users', r)
                setFullList(r)
                setLoading(false)
            })
    }

    useEffect(() => {
        if(filterText && filterText.length) {
            const list = fullList?.filter(item => JSON.stringify(item).toLowerCase().includes(filterText.toLowerCase()))
            setFilteredList(list)
        } else {
            setFilteredList(fullList)
        }
    }, [fullList, filterText])


    useEffect(() => {
        fetchAllUsers()
    }, []);

    function cancelCreateNewUserForm() {
        setShowCreateNewUser(false)
        formNewUser.resetFields();
    }

    const handleSubmit = (values) => {
        var config = {
            method: 'post',
            url: getBaseUrl() + '/user',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem(('token'))
            },
            data: values
        };
        
        axios(config)
            .then(function (response) {
                //console.log(JSON.stringify(response.data));
                fetchAllUsers()
                showNotification('Kund sparad!', 'Användaren har lagts till.')
                cancelCreateNewUserForm()
            })
            .catch(function (error) {
                console.log(error.response)
                let genericError = false
                if(error?.response && error?.response?.data?.message) {
                    const msg = error?.response?.data?.message
                    if(msg === 'USER_ALREADY_EXIST') {
                        showNotification('Ett fel uppstod', 'Användaren finns redan.', 'error')
                    } else {
                        genericError = true
                    }
                } else {
                    genericError = true
                }
                if(genericError) {
                    showNotification('Ett fel uppstod', 'Användaren kunde inte sparas.', 'error')
                }
            })

    }

    const handleChangeUsername = (event) => {
        setUsername(event.target.value)
    }
    const handleChangePassword = (event) => {
        setPassword(event.target.value)
    }

    const EditableCell = ({

                              editing,
                              dataIndex,
                              title,
                              inputType,
                              record,
                              index,
                              children,
                              ...restProps
                          }) => {

        const inputNode = dataIndex === 'tags' ?
            <Select
                mode="tags"
                style={{}}
                placeholder="Tags Mode"
            >

            </Select>
            : <Input
                style={{}
                }
            />;

        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                        rules={[
                            {
                                required: false,
                                message: `Please Input ${title}!`,
                            },
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    const [editingKey, setEditingKey] = useState('');

    const isEditing = (record) => record.id === editingKey;

    const edit = (record) => {
        form.setFieldsValue({
            username: '',
            corporateId: '',
            invoiceCity: '',
            email: '',
            reportToUser: '',


            ...record,
        });
        setEditingKey(record.id);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const updateLocalUser = (userId, updatedValues = {}) => {
        if(Object.keys(updatedValues).length) {
            const index = fullList.findIndex(u => u.id === userId)
            if(index > -1) {
                const updatedList = [...fullList]
                let user = updatedList[index]
                updatedList.splice(index, 1, {...user, ...updatedValues})
                setFullList(updatedList)
            }
        }
    }

    function updateUser(userId, updatedValues) {
        var config = {
            method: 'patch',
            url: getBaseUrl() + '/user/' + userId,
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem(('token'))
            },
            data: updatedValues
        };
        axios(config)
            .then(function (response) {
                //console.log(JSON.stringify(response.data));
                updateLocalUser(userId, updatedValues)
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const save = async (userId) => {
        try {
            const updatedValues = await form.validateFields();

            var config = {
                method: 'patch',
                url: getBaseUrl() + '/user/' + userId,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem(('token'))
                },
                data: updatedValues
            };
            axios(config)
                .then(function (response) {
                    updateLocalUser(userId, updatedValues)
                    setEditingKey('');

                })
                .catch(function (error) {
                    console.log(error);
                    showNotification('Något gick fel', '', 'error')
                });

        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const handleSwitch = () => {

    }

    const columns = [
        {
            title: 'Namn',
            dataIndex: 'username',
            width: '10%',
            fixed: 'left',
            editable: true,
        },
        {
            title: 'Sökord',
            dataIndex: 'tags',
            width: '8%',
            editable: true,
            render: (value, record) => {
                if (value?.length < 1) return

                const tags = value?.map(item => {
                    return <Tag>{item}</Tag>
                })
                return tags
            }

        },
        {
            title: 'Organisations nr',
            dataIndex: 'corporateId',
            width: '10%',
            editable: true,
        },
        {
            title: 'E-postadress',
            dataIndex: 'email',
            width: '8%',
            editable: true,
        },
        {
            title: 'Telefon',
            dataIndex: 'phoneNumber',
            width: '8%',
            editable: true,
        },
        {
            title: 'Fakturaadress',
            dataIndex: 'invoiceCity',
            width: '10%',
            editable: true,
        },
        {
            title: 'Kontakt person',
            dataIndex: 'contactInfo',
            width: '10%',
            editable: true,
        },
        {
            title: 'PIN-kod',
            dataIndex: 'password',
            width: '6%',
            editable: true,
            render: (value, record) => {
                if (value?.length > 6) return ' '

                return value
            }
        },
        {
            title: 'Önskas rapport',
            dataIndex: 'reportToUser',
            width: '7%',
            editable: false,
            render: (value, record) => {
                return <Switch checkedChildren={'Ja'} unCheckedChildren={'Nej'}
                               onChange={(checked) => updateUser(record.id, {reportToUser: checked})}
                               defaultChecked={value}/>
            }
        },
        {
            title: 'Aktiv',
            dataIndex: 'activeUser',
            width: '5%',
            editable: false,
            render: (value, record) => {
                return <Checkbox
                               onChange={(e) => updateUser(record.id, {activeUser: e.target.checked})}
                               defaultChecked={value}/>
            }
        },
        {
            title: '',
            dataIndex: 'operation',
            width: '8%',
            fixed: 'right',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
            <Typography.Link
                onClick={() => save(record.id)}
                style={{}}
            >
              Spara
            </Typography.Link>
                        <br/>
              <a onClick={cancel}>Avbryt</a>
          </span>
                ) : (
                    <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                        Ändra
                    </Typography.Link>
                );
            },
        },
    ];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'age' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    function onSearch(value) {
        setFilterText(value?.target?.value)
    }

    return (

        <div style={{}}>
            {showCreateNewUser ?
                <div>

                    <h2 className="h2">
                        Lägg till användare
                    </h2>

                    <Form
                        form={formNewUser}
                        name="basic"
                        layout='vertical'
                        initialValues={{remember: true}}
                        onFinish={handleSubmit}
                        autoComplete="off"
                    >
                        <div style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            justifyContent: 'space-between'
                        }}>

                            <Form.Item
                                label="Företagsnamn"
                                name="username"
                                style={{width: '45%'}}
                                rules={[{required: true, message: 'Ange företagsnamn!'}]}
                            >
                                <Input
                                    prefix={<UserOutlined className="site-form-item-icon"/>}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Organisations nr"
                                name="corporateId"
                                style={{width: '45%'}}
                                rules={[{required: true, message: 'Ange Organisations nr!', type: ''}]}
                            >
                                <Input
                                    prefix={<FieldBinaryOutlined className="site-form-item-icon"/>}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Email"
                                name="email"
                                style={{width: '45%'}}
                                rules={[{required: true, message: 'Ange rätt email!', type: 'email'}]}
                            >
                                <Input
                                    prefix={<MailOutlined className="site-form-item-icon"/>}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Kontakt person"
                                style={{width: '45%'}}
                                name="contactInfo"
                                rules={[{required: false, message: 'Ange kontakt person!'}]}
                            >
                                <Input
                                    prefix={<UserOutlined className="site-form-item-icon"/>}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Fakturaadress"
                                name="invoiceCity"
                                style={{width: '45%'}}
                                rules={[{required: true, message: 'Ange Fakturaadress!'}]}
                            >
                                <Input
                                    prefix={<ProfileOutlined className="site-form-item-icon"/>}
                                />
                            </Form.Item>

                            <Form.Item
                                label="PIN-kod (Kundnummer som default)"
                                name="password"
                                style={{width: '45%'}}
                                rules={[{required: false, message: 'Ange PIN-kod!', type: ''}]}
                            >
                                <Input
                                    prefix={<LockOutlined className="site-form-item-icon"/>}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Sökord"
                                name="tags"
                                style={{width: '100%'}}
                                rules={[{required: false,}]}
                            >
                                <Select
                                    mode="tags"
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Tags Mode"
                                >

                                </Select>
                            </Form.Item>

                            <Form.Item
                                style={{width: '50%'}}
                            >
                                <Button style={{width: '100%'}}
                                        type="ghost" onClick={cancelCreateNewUserForm}>
                                    Avbryt och gå tillbaka
                                </Button>
                            </Form.Item>

                            <Form.Item
                                style={{width: '50%'}}
                            >
                                <Button className='blackButton' type="primary" htmlType="submit">
                                    Lägg till ny användare
                                </Button>
                            </Form.Item>

                        </div>
                    </Form>
                </div>

                :

                <Form form={form} component={false}>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 20,
                        margin: 10,
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Input
                            placeholder='Sök efter ...'
                            allowClear
                            onChange={onSearch}
                        /> eller
                        <Button
                            onClick={() => setShowCreateNewUser(!showCreateNewUser)}
                            type={'primary'}
                            icon={<PlusOutlined/>}
                        >
                            Skapa ny kund
                        </Button>
                    </div>
                    <Table
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}

                        dataSource={loading ? [] : filteredList}
                        columns={mergedColumns}
                        scroll={{x: 1300}}
                        rowClassName="editable-row"
                        rowKey={record => record.id}
                        locale={{
                            emptyText: loading ? <Skeleton active={true}/> : <Empty/>
                        }}
                        pagination={{
                            onChange: cancel,
                            defaultPageSize: 8,

                        }}
                    />
                </Form>

            }
            <CustomerList title="516161"></CustomerList>
        </div>
    )
}

export default CustomerManagement;
