import React, { useEffect, useState } from "react";
import { Form, Input, Button, Card } from "antd";
import {getBaseUrl} from "./ApiConstants";
import axios from "axios";

const Receipt = ({ orderData, onClose }) => {
    const [user, setUser] = useState("");
    const [order, setOrder] = useState({});
    const [showInputCard, setShowInputCard] = useState(false); 
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');

    const fetchUser = async () => {
        await fetch(`${getBaseUrl()}/user/${orderData.kitsUserId}`, {
            headers: new Headers({
                    Authorization: 'Bearer ' + localStorage.getItem(('token'))
                }
            )
        }).then(r => r.json())
            .then(r => setUser(r))
    }

    useEffect(() => {
        if (orderData) {
            setOrder(orderData);
            fetchUser();
        }
    }, [orderData]);

    async function sendReceipt() {
        let url = `${getBaseUrl()}/cargo/receipt?cargoId=${orderData.id}&email=${user.email}&phone=${user.phoneNumber}`;
        const config = {
            method: 'post',
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem(('token'))
            },
        };
        axios(config)
            .then(function (response) {
                console.debug(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.debug(error.response);
            });
    }

    const handleSendReceipt = () => {
        let url = `${getBaseUrl()}/cargo/receipt?cargoId=${orderData.id}&email=${email}&phone=${phoneNumber}`;
        const config = {
            method: 'post',
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem(('token'))
            },
        };
        axios(config)
            .then(function (response) {
                console.debug(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.debug(error.response);
            });
        setShowInputCard(false); 
    };


    return (
        <div style={{ position: 'relative' }}>
            <Card title="Kvitto" style={{ width: 400 }}>
                <div style={{ marginBottom: '20px' }}>
                    <div style={{ fontWeight: 'bold' }}>{user?.username}</div>
                    <div>{user?.email ? user.email : 'E-mail ej registrerat'}</div>
                    <div>{user?.phoneNumber ? user.phoneNumber : 'Telefonnummer ej registrerat'}</div>
                </div>
                <div>Hej!</div>
                <div style={{ marginBottom: '10px' }}>Vi har tagit emot er last;</div>
                <div>Regnummer: {orderData.vehicle}</div>
                <div>Datum: {orderData.date}</div>
                <div>Littera: {orderData.littera}</div>
                <div>Tid: {orderData.time}</div>
                <div>Material: {orderData.material}</div>
                <div>Vikt: {orderData.weight} ton</div>

                <div>Mvh</div>
                <div>Kits Recycling</div>
                <div style={{ marginTop: '20px' }}>
                    <Button onClick={sendReceipt} type="primary" style={{ marginRight: '10px' }} disabled={!user?.email && !user?.phoneNumber}>
                        Skicka kvitto
                    </Button>
                    <Button type="primary" onClick={() => setShowInputCard(true)}>
                        Ange mottagare
                    </Button>
                </div>

                <div style={{ marginTop: '20px' }}>
                    <Button type="primary" onClick={onClose}>
                        Stäng
                    </Button>
                </div>
            </Card>
            {showInputCard && (
                <div
                    style={{
                        position: 'fixed', 
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', 
                        zIndex: 1000, 
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Card title="Skicka kvitto" style={{ width: 300, zIndex: 1001 }}>
                        <Form layout="vertical">
                            <Form.Item label="Telefonnummer">
                                <Input
                                    placeholder="Lägg till telefonnummer"
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value.replace(/\D/, ''))}
                                    type="text"
                                />
                            </Form.Item>
                            <Form.Item label="E-mail">
                                <Input
                                    placeholder="Fyll i e-mail"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    type="text"
                                />
                            </Form.Item>
                            <Button style={{ marginRight: '20px' }} type="primary" onClick={handleSendReceipt}>
                                Skicka
                            </Button>

                            <Button type="primary" onClick={() => setShowInputCard(false)}>
                                Stäng
                            </Button>
                        </Form>
                    </Card>
                </div>
            )}
        </div>
    );
};

export default Receipt;