import {Button, Card, DatePicker, Input, Select} from "antd";
import React, {useEffect, useState} from "react";
import {getBaseUrl} from "./ApiConstants"
import moment from 'moment/moment';
import locale from 'antd/es/date-picker/locale/sv_SE';

const CargoManagement = () => {

    const [dateInterval, setDateInterval] = useState([])
    const [materialArray, setMaterialArray] = useState([])
    const [username, setUsername] = useState()
    const [userId, setUserId] = useState()
    const [material, setMaterial] = useState('')
    const [litteraArray, setLitteraArray] = useState([])
    const [litteraArray2, setLitteraArray2] = useState([])
    const [littera, setLittera] = useState('')

    useEffect(() => {
        setDefaultDateValues()
        fetchAllUsers()
        fetchAllMaterials()
    }, [])

    function onChangeInterval(date, dateArray) {
        setDateInterval(dateArray.map(d => moment(d)))
    }

    function getReportFromUser() {
        const baseUrl = getBaseUrl() + "/cargo/getCargoCsv"
        let urlParams = new URLSearchParams()

        //console.log(baseUrl + "?" + urlParams)

        if (userId !== 0 && userId !== undefined) {
            urlParams.set('userId', userId)
        }

        if (material !== '') {
            urlParams.set('material', material)
        }
        if (littera !== '') {
            urlParams.set('littera', littera)
        }

        if (dateInterval[0] !== '' && dateInterval[0] !== undefined) {
            const from = dateInterval[0].format('YYYY-MM-DD')
            const to = dateInterval[1].format('YYYY-MM-DD')
            urlParams.set('date1', from);
            urlParams.set('date2', to);
        } else {
            const timeStamp = new Date().getTime()
            const yesterdayTimeStamp = timeStamp - 24 * 60 * 60 * 1000
            const yesterdayDate = new Date(yesterdayTimeStamp)
            const currentDate = new Date(timeStamp)

            const yesterdayYear = yesterdayDate.getFullYear()
            const yesterdayMonth = String(yesterdayDate.getMonth() + 1).padStart(2, '0')
            const yesterdayDay = String(yesterdayDate.getDate() + 1).padStart(2, '0')
            const currentYear = currentDate.getFullYear()
            const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0')
            const currentDay = String(currentDate.getDate() + 1).padStart(2, '0')

            //console.log(currentDay, currentMonth, yesterdayDay, yesterdayMonth)

            urlParams.set('date1', yesterdayYear + '-' + yesterdayMonth + '-' + yesterdayDay)
            urlParams.set('date2', currentYear + '-' + currentMonth + "-" + currentDay)
        }

        //console.log(baseUrl + "?" + urlParams)

        fetch(baseUrl + "?" + urlParams, {
            headers: new Headers({
                    Authorization: 'Bearer ' + localStorage.getItem(('token'))
                }
            )
        }).then(async r => {
            const a = document.createElement("a")
            a.href = "data:text/csv;charset=iso-8859-1," + escape(await r.text());
            a.download = "report.csv"
            a.dispatchEvent(new MouseEvent("click"))
        })
    }

    const [data, setData] = useState([])

    const fetchAllUsers = async () => {
        await fetch(getBaseUrl() + '/user/', {
            headers: new Headers({
                    Authorization: 'Bearer ' + localStorage.getItem(('token'))
                }
            )
        }).then(r => r.json())
            .then(r => updateState(r))
    }
    const updateState = (r) => {
        setData(r)
    }

    const updateMaterialState = (r) => {
        setMaterialArray(r.sort())
    }

    const fetchAllMaterials = () => {
        fetch(getBaseUrl() + '/material/getallmaterials', {
            headers: new Headers({
                    Authorization: 'Bearer ' + localStorage.getItem(('token'))
                }
            )
        }).then(r => (r.json())
            .then(r => updateMaterialState(r)))
    }

    const handleUsernameChange = (v) => {
        setUsername(v)
        for (const element of data) {
            if (element.username === v) {
                setUserId(element.id)
            }
        }
    }

    const handleSelect = (username) => {
        fetch(getBaseUrl() + '/cargo/littera/', {
            headers: new Headers({
                    Authorization: 'Bearer ' + localStorage.getItem(('token'))
                }
            )
        }).then(r => (r.json()).then(
            r => setLitteraArray(r)))
    }

    useEffect(() => {
        handleSelect(username)
    }, [username]);


    useEffect(() => {
        addKeyToUsernames()
    }, [data]);

    useEffect(() => {
        addKeyToMaterial()
    }, [materialArray]);

    useEffect(() => {
        addKeyToLittera()
    }, [litteraArray]);


    const [usernameArray, setUsernameArray] = useState([])
    const addKeyToUsernames = () => {
        let tempArr = [];
        for (let i = 0; i < data.length; i++) {
            tempArr.push(
                {
                    value: (data[i].username)
                }
            )
        }

        setUsernameArray(tempArr)
    }

    const [materialArray2, setMaterialArray2] = useState([])
    const addKeyToMaterial = () => {
        let tempArr = [];
        for (let i = 0; i < materialArray.length; i++) {
            tempArr.push(
                {
                    value: materialArray[i]
                }
            )
        }
        setMaterialArray2(tempArr)
    }

    const addKeyToLittera = () => {
        let tempArr = [];
        for (let i = 0; i < litteraArray.length; i++) {
            tempArr.push(
                {
                    value: litteraArray[i]
                }
            )
        }
        setLitteraArray2(tempArr)
    }

    const setDefaultDateValues = () => {
        var now = moment()
        var monday = now.clone().weekday(-7);
        var friday = now.clone().weekday(-3);

        const range = [monday, friday]
        setDateInterval(range)
    }

    return (
        <Card>
            <h2>Filter</h2>
            <Card bodyStyle={{display: "flex", gap: "20px"}}>
            <Select
                showArrow={false}
                allowClear
                showSearch
                className="autoComplete"
                options={usernameArray}
                placeholder="Filtrera på användarnamn"
                filterOption={(inputValue, option) =>
                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
                onSelect={(v) => handleUsernameChange(v)}
                onClear={() => setUserId(undefined)}
            >
            </Select>

            <Select
                showArrow={false}
                allowClear
                showSearch
                className="autoComplete"
                options={materialArray2}
                placeholder="Filtrera på material"
                filterOption={(inputValue, option) =>
                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
                onSelect={(v) => setMaterial(v)}
                onClear={() => setMaterial('')}
            >
                <Input style={{height: '50px'}}/>
            </Select>

            <Select
                showArrow={false}
                allowClear
                showSearch
                className="autoComplete"
                options={litteraArray2}
                placeholder="Filtrera på littra"
                filterOption={(inputValue, option) =>
                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
                onSelect={(v) => setLittera(v)}
                onClear={() => setLittera('')}
            >
                <Input style={{height: '50px'}}/>
            </Select>

            <DatePicker.RangePicker
                className="datePicker"
                placeholder={["Startdatum", "Slutdatum"]}
                onChange={onChangeInterval}
                locale={locale}
                value={dateInterval}
            />
            </Card>

            <h2 className="h2 reportHeaderPadding">Rapport</h2><h3 style={{color: "#999"}}>(Laddar ner en rapport med aktuella filterval)</h3>
            <form onSubmit={getReportFromUser}>

                <Button block className='blackButton' onClick={getReportFromUser} type="primary"
                        value="Ladda ned rapport">Ladda ner rapport</Button>
            </form>
        </Card>
    )
}

export default CargoManagement;


// Metod för att littera ska hämtas vid username select. Ta tillbaka om kund kräver detta.

// const handleUsernameSelect = (username) => {
//     console.log(username)
//     fetch(getBaseUrl() + 'cargo/littera/?userId=' + userId, {
//         headers: new Headers({
//                 Authorization: 'Bearer ' + localStorage.getItem(('token'))
//             }
//         )
//     }).then(r => (r.json()).then(
//         r => setLitteraArray(r)))
// }


// Denna useEffect är knuten till metoden ovan

// useEffect(() => {
//     if (username !== undefined){
//         handleUsernameSelect(username)
//         console.log(data)
//     }
// }, [username]);
