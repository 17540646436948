import {
    BrowserRouter,
    Routes,
    Route,
    Outlet
} from "react-router-dom";
import Dashboard from "./views/Dashboard";
import LoginPage from "./views/LoginPage";
import logo from "./kits.png";
import React from "react";
import './App.less';
import { AppContextProvider } from "./contexts/AppContext";

function App() {

    return (
        <BrowserRouter>
            {/*<div className="container">
                <div>
                    <img className="image" src={logo} alt=""/>
                </div>
                </div>*/}
            <AppContextProvider>
                <Routes>
                    <Route path="/" element={<LoginPage />} />
                    <Route path="report" element={<Dashboard />} />
                </Routes>
            </AppContextProvider>
        </BrowserRouter>
    );
}

export default App;
