import {Button, Card, Typography, Alert} from "antd";
import React, {useContext, useEffect, useState} from "react";
import ComponentSwitcher from "../components/ComponentSwitcher";
import logo from "../kitslogo.png";
import {useNavigate} from "react-router-dom";
import { AppContext } from "../contexts/AppContext";

const Dashboard = () => {

    const { alertData } = useContext(AppContext)

    const [navValue, setNavValue] = useState(0)
    const [value, setValue] = useState('')
    const {Title} = Typography;
    let navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('token')
        navigate('/')
    }

    let styles = {
        containerImage: {
            backgroundImage: 'url("https://kitscontractor.se/wp-content/uploads/2021/03/Underhall-1.jpg")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            minWidth: '100%',
            minHeight: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 0,

        },
        loginContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',

        },
        input: {
            marginBottom: 20
        },

    };

    return (

        <div style={styles.containerImage}>

            <Card className="container-dashboard">
                <img className="imageLogo" src={logo} alt=""/>
                <div className="logOut">
                    <Button onClick={() => handleLogout()}>Logga ut</Button>
                </div>

                {/*<Title className='loginBtn'>Kontrollpanel</Title>*/}
                <div className="sidenav">
                    <Button style={navValue === 0 ? { backgroundColor: '#439D5B', color: 'white', borderColor: '#1a4223' } : null} className="navbutton" onClick={() => setNavValue(0)}>Kundhantering</Button>
                    <Button style={navValue === 3 ? { backgroundColor: '#439D5B', color: 'white', borderColor: '#1a4223' } : null} className="navbutton" onClick={() => setNavValue(3)}>Kontrollogg</Button>
                    <Button style={navValue === 1 ? { backgroundColor: '#439D5B', color: 'white', borderColor: '#1a4223' } : null} className="navbutton" onClick={() => setNavValue(1)}>Lastrapporter</Button>
	                  <Button style={navValue === 2 ? { backgroundColor: '#439D5B', color: 'white', borderColor: '#1a4223' } : null} className="navbutton" onClick={() => setNavValue(2)}>Redigera last</Button>
                </div>

                <div className="componentSwitcher">
                    <ComponentSwitcher navValue={navValue}></ComponentSwitcher>
                </div>

            </Card>
        </div>
    )

}


export default Dashboard
