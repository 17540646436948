import {useEffect, useLayoutEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {getBaseUrl} from "./ApiConstants";

const CustomerList = (props) => {
    let [templist, setTempList] = useState([])


    const fetchUsers = async () => {

        const response = await fetch(getBaseUrl() + '/user')
        let customerList = await response.json()

        setTempList(customerList)

    }

useEffect(
    () => {
        // fetchUsers()
    }
)

    return (
        <div>
            <ul>{
                templist.map((e, i) =>
                    <li key={i}>
                        {e.username}
                    </li>)
            }</ul>
        </div>
    )

}

export default CustomerList

