import React, { createContext, useState, useEffect } from "react";
import { notification } from 'antd';

const AppContext = createContext({});

const AppContextProvider = ({ children }) => {

    const showNotification = (message, description = '', type = 'success', autohideDelay = 5000) => {
        // VALID TYPES: warning, success, info, error
        if (message) {
            notification[type]({
                message,
                description,
                duration: autohideDelay
            })
        }
    }

    return (
        <AppContext.Provider
            value={{
                showNotification,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export { AppContext, AppContextProvider };
